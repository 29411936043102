import React, { useEffect, useState } from "react";
import Classes from "./dashboard.module.scss";
import Overview from "../Overview";
import { Col, Row, Select } from "antd";
import Bars from "../../assets/images/icons/yellowBar.svg";
import { getProjects, updateProjectStatus } from "../../api/services/project";
import { addFund, getProjectStats } from "../../api/services/platformFund";
import useApprove from "../../contract/hooks/token/useApprove";
import { ethers } from "ethers";
import useAddPlatformFund from "../../contract/hooks/stake/useAddPlatformFund";
import { CONTRACT_INFO } from "../../contract";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePercentage,
  decimalnumbervalidator,
  extractErrorMessage,
} from "../../helpers/common";
import useGetBlnc from "../../contract/hooks/token/useGetBlnc";
// import useGetProjectsPoolPercentages from "../../contract/hooks/stake/useGetProjectsPoolPercentages";
// code

function YourDashboard() {
  const titles = ["Drip Marble Run", "Warrior Rush", "Liquid Warrior"];
  const [projects, setProjects] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [amount, setAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const [projectStats, setProjectStats] = useState(null);
  const { address } = useAccount();
  const { connectedAddress } = useSelector((state) => state.auth);
  const {
    approve, // Renamed the function
    approveSentError,
    approveTxError,
    isApproveCompleted,
  } = useApprove();
  const {
    platformFundSentTx,
    platformFund,
    isPlatformFundCompleted,
    platformFundSentError,
    platformFundTxError,
  } = useAddPlatformFund();
  // const {
  //   devFeePercentage,
  //   poolPercentages,
  //   refetch: refetchProjectPoolPercentages,
  // } = useGetProjectsPoolPercentages();
  const { blnc, refetch } = useGetBlnc(address);
  const getProjectsList = async () => {
    const res = await getProjects();
    if (res?.data?.data.length > 0) {
      formatProjects(res.data.data);
      setProjectId(res?.data?.data[0]?._id);
    }
  };
  const getProjectAdminStats = async () => {
    const res = await getProjectStats();
    setProjectStats(res.data?.percentages);
  };
  useEffect(() => {
    if (isApproveCompleted) {
      platformFund({
        args: [ethers.utils.parseEther(`${amount}`), 1],
        from: address,
      });
    }
  }, [isApproveCompleted]);
  useEffect(() => {
    const addPlatformFund = async () => {
      const res = await addFund({
        projectId,
        amount: parseInt(amount),
        txHash: platformFundSentTx.hash,
      });
    };
    if (platformFundSentTx) {
      addPlatformFund();
    }
  }, [platformFundSentTx]);
  useEffect(() => {
    if (isPlatformFundCompleted) {
      setLoader(false);
      toast.success("Fund added successfully");
      setAmount("");
      // refetchProjectPoolPercentages();
      getProjectsList();
      getProjectAdminStats();
      if (projects?.length > 0) {
        setProjectId(projects[0]?._id);
      }
    }
  }, [isPlatformFundCompleted]);
  useEffect(() => {
    if (
      (platformFundSentError,
      platformFundTxError,
      approveSentError,
      approveTxError)
    ) {
      const error =
        platformFundTxError ||
        platformFundSentError ||
        approveSentError ||
        approveTxError;
      setLoader(false);
      toast.error(extractErrorMessage(error.message));
    }
  }, [
    platformFundSentError,
    platformFundTxError,
    approveSentError,
    approveTxError,
  ]);
  useEffect(() => {
    if (connectedAddress) {
      getProjectsList();
      getProjectAdminStats();
    }
  }, [connectedAddress]);
  const formatProjects = (data) => {
    const formatedData = data.map((item) => {
      return {
        label: item.name,
        value: item._id,
      };
    });
    setProjectId(formatedData[0].value);
    setProjects(formatedData);
  };
  const handleChange = (value) => {
    setProjectId(value);
  };
  const handleSubmit = async () => {
    setLoader(true);
    approve({
      args: [CONTRACT_INFO.stake.address, ethers.utils.parseEther(`${amount}`)],
      from: address,
    });
  };
  const handleProjectStatus = async (id, isEnabled) => {
    const res = await updateProjectStatus(id, { isEnabled });
    if (res.status === 200) {
      getProjectsList();
      getProjectAdminStats();
      toast.success('Project updated successfully')
    }
  };

  return (
    <div className={Classes.dashboard}>
      <div className={Classes.heading}>
        <img src={Bars} alt="icon" />
        <p>Dashboard</p>
      </div>
      <div className="mx-3">
        <Row gutter={[20, 20]}>
          {projectStats &&
            projectStats?.length > 0 &&
            projectStats.map((project, i) => (
              <Col lg={12} md={24} sm={24} xs={24} key={i}>
                <Overview
                  data={project}
                  action={() =>
                    handleProjectStatus(project?._id, !project?.isEnabled)
                  }
                  isEnabled={project?.isEnabled}
                />
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
}

export default YourDashboard;
