import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useDistributeReward = ({setLoader}) => {
  const {
    isError: isDistributeRewardSentError,
    data: distributeRewardSentTx,
    isLoading: isDistributeRewardTxInProgress,
    isSuccess: isDistributeRewardTxSent,
    write: distibuteReward, // Function to interact with the distributeReward contract
    error: distributeRewardSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "weeklyTransfer", // Updated function name
  });

  const {
    isLoading: isDistributeRewardWaiting,
    isSuccess: isDistributeRewardCompleted,
    isError: isDistributeRewardError,
    error: distributeRewardTxError,
    data: distributeRewardResp
  } = useWaitForTransaction({
    hash: distributeRewardSentTx?.hash,
  });

  useEffect(()=>{
    const  error=distributeRewardSentError||distributeRewardTxError
    if(error){
        setLoader(false)
        toast.error(extractErrorMessage(error.message))
    }

  },[distributeRewardSentError,distributeRewardTxError])

  return {
    // distributeReward operation
    isDistributeRewardSentError,
    distributeRewardSentTx,
    isDistributeRewardTxInProgress,
    isDistributeRewardTxSent,
    distibuteReward,
    isDistributeRewardCompleted,
    isDistributeRewardError,
    isDistributeRewardWaiting,
    distributeRewardSentError,
    distributeRewardTxError,
    distributeRewardResp
  };
};

export default useDistributeReward;
