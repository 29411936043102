import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useChangePlatformPercentages = (setLoader) => {
  const {
    isError: isDoPoolSentError,
    data: doPoolSentTx,
    isLoading: isDoPoolTxInProgress,
    isSuccess: isDoPoolTxSent,
    write: setDoPool, // Function to interact with the doPool contract
    error: doPoolSentError,
  } = useContractWrite({
    address,
    abi,
    functionName: "setdOPoolPercentage", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isDoPoolWaiting,
    isSuccess: isDoPoolCompleted,
    isError: isDoPoolError,
    error: doPoolTxError,
    data: doPoolResp,
  } = useWaitForTransaction({
    hash: doPoolSentTx?.hash,
  });
  /////// 2
  const {
    isError: isIOPoolSentError,
    data: IOPoolSentTx,
    isLoading: isIOPoolTxInProgress,
    isSuccess: isIOPoolTxSent,
    write: setIOPoolPercentage, // Function to interact with the IOPool contract
    error: IOPoolSentError,
  } = useContractWrite({
    address,
    abi,
    functionName: "setIOPoolPercentage", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isIOPoolWaiting,
    isSuccess: isIOPoolCompleted,
    isError: isIOPoolError,
    error: IOPoolTxError,
    data: IOPoolResp,
  } = useWaitForTransaction({
    hash: IOPoolSentTx?.hash,
  });
  ////// 3
  const {
    isError: isMFeeSentError,
    data: MFeeSentTx,
    isLoading: isMFeeTxInProgress,
    isSuccess: isMFeeTxSent,
    write: setMaintainceFeePercentage, // Function to interact with the MFee contract
    error: MFeeSentError,
  } = useContractWrite({
    address,
    abi,
    functionName: "OPpercentageDistribution", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isMFeeWaiting,
    isSuccess: isMFeeCompleted,
    isError: isMFeeError,
    error: MFeeTxError,
    data: MFeeResp,
  } = useWaitForTransaction({
    hash: MFeeSentTx?.hash,
  });
  //// 4
  const {
    isError: isFlowTSentError,
    data: flowTSentTx,
    isLoading: isFlowTTxInProgress,
    isSuccess: isFlowTTxSent,
    write: setFlowToTeasuryPercentage, // Function to interact with the flowT contract
    error: flowTSentError,
  } = useContractWrite({
    address,
    abi,
    functionName: "setflowToTreasuryPercentage", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isFlowTWaiting,
    isSuccess: isFlowTCompleted,
    isError: isFlowTError,
    error: flowTTxError,
    data: flowTResp,
  } = useWaitForTransaction({
    hash: flowTSentTx?.hash,
  });
  //////5
  const {
    isError: isDPSentError,
    data: DPSentTx,
    isLoading: isDPTxInProgress,
    isSuccess: isDPTxSent,
    write: setDividentPayoutPercentage, // Function to interact with the DP contract
    error: DPSentError,
  } = useContractWrite({
    address,
    abi,
    functionName: "settdividentPayoutPercentage", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isDPWaiting,
    isSuccess: isDPCompleted,
    isError: isDPError,
    error: DPTxError,
    data: DPResp,
  } = useWaitForTransaction({
    hash: DPSentTx?.hash,
  });

  //////
  //////5
  const {
    isError: isODPSentError,
    data: ODPSentTx,
    isLoading: isODPTxInProgress,
    isSuccess: isODPTxSent,
    write: setODividentPayoutPercentage, // Function to interact with the DP contract
    error: ODPSentError,
  } = useContractWrite({
    address,
    abi,
    functionName: "setodividentPayoutPercentage", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isODPWaiting,
    isSuccess: isODPCompleted,
    isError: isODPError,
    error: ODPTxError,
    data: ODPResp,
  } = useWaitForTransaction({
    hash: ODPSentTx?.hash,
  });

  /////
  /////6
  const {
    isError: isWOSentError,
    data: WOSentTx,
    isLoading: isWOTxInProgress,
    isSuccess: isWOTxSent,
    write: setWOPecentage, // Function to interact with the WO contract
    error: WOSentError,
  } = useContractWrite({
    address,
    abi,
    functionName: "setwOPoolPercentage", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isWOWaiting,
    isSuccess: isWOCompleted,
    isError: isWOError,
    error: WOTxError,
    data: WOResp,
  } = useWaitForTransaction({
    hash: WOSentTx?.hash,
  });
  useEffect(() => {
    const error =
      ODPTxError ||
      ODPSentError ||
      DPTxError ||
      DPSentError ||
      flowTTxError ||
      flowTSentError ||
      MFeeTxError ||
      MFeeSentError
      if(error){
        toast.error(extractErrorMessage(error.message))
        if(setLoader){
          setLoader(null)
        }
      }
  }, [
    isDPError,
    isDPSentError,
    isFlowTError,
    isFlowTSentError,
    isODPError,
    isODPSentError,
    isMFeeError,
    isMFeeSentError,
  ]);

  return {
    isDoPoolSentError,
    doPoolSentTx,
    isDoPoolTxInProgress,
    isDoPoolTxSent,
    setDoPool, // Function to interact with the doPool contract
    doPoolSentError,

    isDoPoolWaiting,
    isDoPoolCompleted,
    isDoPoolError,
    doPoolTxError,
    doPoolResp,

    isIOPoolSentError,
    IOPoolSentTx,
    isIOPoolTxInProgress,
    isIOPoolTxSent,
    setIOPoolPercentage, // Function to interact with the IOPool contract
    IOPoolSentError,

    isIOPoolWaiting,
    isIOPoolCompleted,
    isIOPoolError,
    IOPoolTxError,
    IOPoolResp,

    isMFeeSentError,
    MFeeSentTx,
    isMFeeTxInProgress,
    isMFeeTxSent,
    setMaintainceFeePercentage, // Function to interact with the MFee contract
    MFeeSentError,

    isMFeeWaiting,
    isMFeeCompleted,
    isMFeeError,
    MFeeTxError,
    MFeeResp,

    isFlowTSentError,
    flowTSentTx,
    isFlowTTxInProgress,
    isFlowTTxSent,
    setFlowToTeasuryPercentage, // Function to interact with the flowT contract
    flowTSentError,

    isFlowTWaiting,
    isFlowTCompleted,
    isFlowTError,
    flowTTxError,
    flowTResp,

    isDPSentError,
    DPSentTx,
    isDPTxInProgress,
    isDPTxSent,
    setDividentPayoutPercentage, // Function to interact with the DP contract
    DPSentError,

    isDPWaiting,
    isDPCompleted,
    isDPError,
    DPTxError,
    DPResp,

    isWOSentError,
    WOSentTx,
    isWOTxInProgress,
    isWOTxSent,
    setWOPecentage, // Function to interact with the WO contract
    WOSentError,

    isWOWaiting,
    isWOCompleted,
    isWOError,
    WOTxError,
    WOResp,

    ////
    isODPSentError,
    ODPSentTx,
    isODPTxInProgress,
    isODPTxSent,
    setODividentPayoutPercentage, // Function to interact with the DP contract
    ODPSentError,

    isODPWaiting,
    isODPCompleted,
    isODPError,
    ODPTxError,
    ODPResp,
  };
};

export default useChangePlatformPercentages;
