import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";

const address = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useDisableUser = ({setLoader}) => {
  const {
    isError: isDisableUserSentError,
    data: disableUserSentTx,
    isLoading: isDisableUserTxInProgress,
    isSuccess: isDisableUserTxSent,
    write: updateUserStatus, // Function to interact with the disableUser contract
    error: disableUserSentError
  } = useContractWrite({
    address,
    abi,
    functionName: "blockUser", // Updated function name
  });

  const {
    isLoading: isDisableUserWaiting,
    isSuccess: isDisableUserCompleted,
    isError: isDisableUserError,
    error: disableUserTxError,
    data: disableUserResp
  } = useWaitForTransaction({
    hash: disableUserSentTx?.hash,
  });
  useEffect(()=>{
    const error=disableUserSentError||disableUserTxError
    if(error){
      setLoader(false)
      toast.error(extractErrorMessage(error.message))
    }

  },[disableUserSentError,disableUserTxError])

  return {
    // disableUser operation
    isDisableUserSentError,
    disableUserSentTx,
    isDisableUserTxInProgress,
    isDisableUserTxSent,
    updateUserStatus,
    isDisableUserCompleted,
    isDisableUserError,
    isDisableUserWaiting,
    disableUserSentError,
    disableUserTxError,
    disableUserResp
  };
};

export default useDisableUser;
