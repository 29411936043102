import React from "react";
import Classes from "./overview.module.scss";
// import useGetProjectPoolPercentage from "../../contract/hooks/stake/useGetProjectPoolPercentage";
import { calculatePercentage, formatLargeNumber } from "../../helpers/common";
import { useNavigate } from "react-router-dom";
function Overview({ data, isEnabled,action }) {
  const { totalAmount, name, pId,_id,treasurePoolPercentage,ownerShipPoolPercentage } = data;
  // const { percentage } = useGetProjectPoolPercentage(pId);
  const navigate=useNavigate()
  return (
    <div className={Classes.overview}>
      <h1 className={Classes.title}>{name}</h1>
      <div className={`${Classes.card} ${Classes.border_bottom}`}>
        <div className="d-flex align-items-center justify-content-between flex-md-row flex-column gap-2">
          <h3 className={Classes.title}>{`${formatLargeNumber(totalAmount) || 0} `}</h3>
          <p className={Classes.subtitle}>Total Investment</p>
        </div>
      </div>
      <div className={`${Classes.card} ${Classes.border_bottom}`}>
        <div className="d-flex align-items-center justify-content-between flex-md-row flex-column gap-2">
          <span className="d-flex  align-items-center justify-content-center ">
            <h3 className={Classes.title}>
              {/* ${formatLargeNumber(calculatePercentage(ownerShipPoolPercentage, totalAmount)||0) } */}
              {ownerShipPoolPercentage||0}%
            </h3>
            {/* <p className="mx-2" style={{ color: "red" }}>
              {ownerShipPoolPercentage||0}%
            </p> */}
          </span>
          <p className={Classes.subtitle}>Ownership Pool %</p>
        </div>
      </div>
      <div className={`${Classes.card} ${Classes.border_bottom}`}>
        <div className="d-flex align-items-center justify-content-between flex-md-row flex-column gap-2">
          <span className="d-flex  align-items-center justify-content-center ">
            <h3 className={Classes.title}>
              {/* ${formatLargeNumber(calculatePercentage(treasurePoolPercentage, totalAmount) || 0)} */}
              {treasurePoolPercentage||0}%
            </h3>
            {/* <p className="mx-2" style={{ color: "red" }}>
              {treasurePoolPercentage||0}%
            </p> */}
          </span>
          <p className={Classes.subtitle}>Treasury Pool %</p>
        </div>
      </div>
      {
        <>
        <button className={`${Classes.btn} mt-4`} onClick={()=>action()}>
          {isEnabled ? "Disable" : "Enable"}
        </button>
          <button className={`${Classes.btn} mt-4 mx-4`} onClick={()=>navigate(`/edit-project/${_id}`)}>
          { "Edit"}
        </button>
        </>
      }

      {/* <div className={`${Classes.card}`}>
        <div className="d-flex align-items-center justify-content-between flex-md-row flex-column gap-2">
        <span className="d-flex  align-items-center justify-content-center ">
          <h3 className={Classes.title}>${devFeeShare || 0}</h3>
          <p className="mx-2" style={{color:"red"}}>{devFeeSharePercentage}%</p>
          </span>
          <p className={Classes.subtitle}>Dev Fee Share</p>
        </div>
      </div> */}
    </div>
  );
}

export default Overview;
