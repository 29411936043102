// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import {CONTRACT_INFO} from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";
const stakeContractAddress = CONTRACT_INFO.stake.address;
const abi=CONTRACT_INFO.stake.abi

const useStake = (setLoader) => {
  //approve contract

  const {
    isError: isStakeSentError,
    data: stakeSentTx,
    isLoading: isStakeTxInProgress,
    isSuccess: isStakeTxSent,
    write: stake,
    error: stakeSentError
  } = useContractWrite({
    address: stakeContractAddress,
    abi,
    functionName: "deductAmountByOwner",
  });

  const {
    isLoading: isStakeWaiting,
    isSuccess: isStakeCompleted,
    isError: isStakeError,
    error: stakeTxError,
    data: stakeResp
  } = useWaitForTransaction({
    hash: stakeSentTx?.hash,
  });
  useEffect(()=>{
    const error=stakeSentError||stakeTxError
    if(error){
        toast.error(extractErrorMessage(error?.message))
        if(setLoader){
            setLoader(false)
        }
    }

  },[isStakeSentError,isStakeError])
  return {
    //stake operation
    isStakeSentError,
    stakeSentTx,
    isStakeTxInProgress,
    isStakeTxSent,
    stake,
    isStakeCompleted,
    isStakeError,
    isStakeWaiting,
    stakeSentError,
    stakeTxError,
    stakeResp
  };
};

export default useStake;
