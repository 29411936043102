import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";

const ownershipPoolContractAddress = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useAddOwnershipPool = () => {
  const {
    isError: isOwnershipPoolSentError,
    data: ownershipPoolSentTx,
    isLoading: isOwnershipPoolTxInProgress,
    isSuccess: isOwnershipPoolTxSent,
    write: addOwnershipPool, // Function to interact with the ownershipPool contract
    error: ownershipPoolSentError
  } = useContractWrite({
    address: ownershipPoolContractAddress,
    abi,
    functionName: "addOwnershipPool", // Assuming this function remains the same in your ABI
  });

  const {
    isLoading: isOwnershipPoolWaiting,
    isSuccess: isOwnershipPoolCompleted,
    isError: isOwnershipPoolError,
    error: ownershipPoolTxError,
    data: ownershipPoolResp
  } = useWaitForTransaction({
    hash: ownershipPoolSentTx?.hash,
  });

  return {
    // ownershipPool operation
    isOwnershipPoolSentError,
    ownershipPoolSentTx,
    isOwnershipPoolTxInProgress,
    isOwnershipPoolTxSent,
    addOwnershipPool,
    isOwnershipPoolCompleted,
    isOwnershipPoolError,
    isOwnershipPoolWaiting,
    ownershipPoolSentError,
    ownershipPoolTxError,
    ownershipPoolResp
  };
};

export default useAddOwnershipPool;
