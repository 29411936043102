import { ethers } from 'ethers'; 
export const decimalnumbervalidator = (evt, allowDecimals = false, max = null) => {
  let e = evt || window.event;

  // Check for the wheel event and prevent its default action
  if (e.key === "ArrowDown") {
    e.preventDefault();
    return;
  }

  // Allow: backspace, delete, tab, escape, enter and .
  const specialKeys = [46, 8, 9, 27, 13, 110];

  // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Command+A
  if (
    specialKeys.includes(e.keyCode) ||
    ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) &&
      (e.ctrlKey === true || e.metaKey === true)) ||
    (e.keyCode >= 35 && e.keyCode <= 40)
  ) {
    return; // let it happen, don't do anything
  }

  let key = e.keyCode || e.which;

  // Get the input value
  const input = e.target.value;
  const decimalPosition = input.indexOf('.');

  // If decimals are not allowed and the key is a decimal point, prevent input
  if (!allowDecimals && (key === 190 || key === 110)) {
    e.returnValue = false;
    if (e.preventDefault) e.preventDefault();
    return;
  }

  // If decimals are allowed, check for decimal places restriction
  if (allowDecimals) {
    // If there is a decimal point and the number of decimal places is 6 or more, prevent input
    if (decimalPosition !== -1 && input.length - decimalPosition > allowDecimals) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
      return;
    }
  }

  // Allow numbers and special keys
  if (
    !e.shiftKey &&
    !e.altKey &&
    !e.ctrlKey &&
    (key === 190 ||
      key === 110 ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 35 ||
      key === 36 ||
      key === 37 ||
      key === 39 ||
      key === 46 ||
      key === 45)
  ) {
    // Check if the new value would exceed the max value
    let newValue = input + e.key;
    
    if (allowDecimals && decimalPosition !== -1) {
      newValue = parseFloat(newValue);
    } else {
      newValue = parseInt(newValue, 10);
    }

    if (max !== null && newValue > max) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
      return;
    }

  } else {
    e.returnValue = false;
    if (e.preventDefault) e.preventDefault();
  }
};
export const extractErrorMessage = (errorMessage) => {
  // Check for MetaMask signature denial
  const metaMaskSignatureKey = "MetaMask Tx Signature: ";
  if (errorMessage.includes(metaMaskSignatureKey)) {
    const extractedMessage = errorMessage
      .split(metaMaskSignatureKey)[1]
      .trim();
    const meaningfulMessage = extractedMessage.split("Version:")[0].trim();
    return meaningfulMessage;
  }

  // const regex = /Error:\s(.+?)\s*\(.*?\)\s*\((\d+)\)/;
  const regex = /Error:\s(.+)/;

  const match = errorMessage.match(regex);
  if (match) {
    const errorMessage = match[1];
    // const projectNo = match[2];
    return errorMessage;
  } else {
    return errorMessage;
  }
};
  
  export const firstInputCharacterSpace = (evt, value) => {
    let e = evt || window.event;
    if (value === 0 && e.which === 32) e.preventDefault();
  };
  export function calculatePercentage(part, whole,i) {
    return (whole*(part/100))?.toFixed(2)
}
export const formatLargeNumber=(number)=> {
  let fixedNumber=null
  if(typeof number!=="number"){
    fixedNumber= Number(Number(number).toFixed(2))      
  }else {
    fixedNumber=Number(number.toFixed(2))
  }

  if (fixedNumber < 1000) {
    return fixedNumber;
  } else if (fixedNumber >= 1000 && fixedNumber < 1_000_000) {
    return (fixedNumber / 1000).toFixed(1) + "K";
  } else if (fixedNumber >= 1_000_000 && fixedNumber < 1_000_000_000) {
    return (fixedNumber / 1_000_000).toFixed(1) + "M";
  } else if (fixedNumber >= 1_000_000_000 && fixedNumber < 1_000_000_000_000) {
    return (fixedNumber / 1_000_000_000).toFixed(1) + "B";
  } else if (fixedNumber >= 1_000_000_000_000 && fixedNumber < 1_000_000_000_000_000) {
    return (fixedNumber / 1_000_000_000_000).toFixed(1) + "T";
  }
}
export const validatePreInvestmentJsonFile=(array)=>{
  
  if (!Array.isArray(array)) {
    return "Input is not an array.";
  }

  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    
    // Check if 'walletAddress' and 'amount' fields exist
    if (!item?.hasOwnProperty("walletAddress") || !item?.hasOwnProperty("amount")) {
      return `Error: Wallet address or amount is missing`;
    }

    // Check if 'walletAddress' is a string
    if (typeof item?.walletAddress !== "string"||!ethers.utils.isAddress(item?.walletAddress)) {
      return `Error:This ${item?.walletAddress} wallet address is wrong in JSON file`;;
    }

    // Check if 'amount' is a number
    if (typeof item.amount !== "number" || isNaN(item.amount)) {
      return `Error: amount of address  ${item?.walletAddress} is wrong`;
    }

    // Check if 'amount' is non-negative
    if (item.amount < 0) {
      return `Error: amount at index ${i} should be a non-negative number.`;
    }
  }

  return null; // Return null if everything is valid
}