import api from "../index";
const createStake = (data) => {
  return api.post('/user/stake',data)
};
const updateStake = (id,txHash) => {
    return api.post(`/stake/update/${id}`,{txHash})
  };
  const completeStack = (data) => {
    return api.post(`/transaction/complete`,data)
  };


export { createStake,updateStake,completeStack };
