import DashboardLayout from "../layout/DashboardLayout";
import MainDashboard from "../components/Dashboard";
import Login from "../components/Login";
import Layout from "../layout/SimpleLayout";
import Bot from "../components/Bot";
import Funds from "../components/Funds";
import PercentageChanges from "../components/PercentageChanges";
import Projects from "../components/Projects";
import Users from "../components/Users";
import Payout from "../components/Payout";
import PreInvestment from "../components/PreInvestment";
import DeductUserAmount from "../components/DeductUserAmount";
export const routes = [
  {
    path: "/login",
    access: true,
    exact: true,
    title: "login",
    layout: Layout,
    component: Login,
  },
  {
    path: "/",
    access: false,
    exact: true,
    title: "dashboard",
    layout: DashboardLayout,
    component: MainDashboard,
  },
  {
    path: "/bot",
    access: false,
    exact: true,
    title: "bot",
    layout: DashboardLayout,
    component: Bot,
  },
  {
    path: "/percentage-changes",
    access: false,
    exact: true,
    title: "funds",
    layout: DashboardLayout,
    component: PercentageChanges,
  },
  {
    path: "/add-funds",
    access: false,
    exact: true,
    title: "funds",
    layout: DashboardLayout,
    component: Funds,
  },
  {
    path: "/add-project",
    access: false,
    exact: true,
    title: "funds",
    layout: DashboardLayout,
    component: Projects,
  },
  {
    path: "/edit-project/:id",
    access: false,
    exact: true,
    title: "funds",
    layout: DashboardLayout,
    component: Projects,
  },
  {
    path: "/users",
    access: false,
    exact: true,
    title: "user",
    layout: DashboardLayout,
    component: Users,
  },
  {
    path: "/payout",
    access: false,
    exact: true,
    title: "payout",
    layout: DashboardLayout,
    component: Payout,
  },
  {
    path: "/pre-investment",
    access: false,
    exact: true,
    title: "pre-investment",
    layout: DashboardLayout,
    component: PreInvestment,
  },
  {
    path: "/deduct-user-amount",
    access: false,
    exact: true,
    title: "deduct-user-amount",
    layout: DashboardLayout,
    component: DeductUserAmount,
  },
];
