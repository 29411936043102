// ** WEB3 Imports
import { useState, useEffect } from "react";

import { useContractRead } from "wagmi";
import { ethers } from "ethers";
import { CONTRACT_INFO } from "../../index";
const useGetUserInfo= (address) => {
    const isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(address);
  const [info, setInfo] = useState(null);
  const {
    data,
    isSuccess,
    refetch,
  } = useContractRead({
    address: CONTRACT_INFO.stake.address,
    abi: CONTRACT_INFO.stake.abi,
    functionName: "userRegistered",
    args:[address],
    enabled:isValidAddress
  });
 
  useEffect(() => {
    if (data) {
        setInfo (Number(ethers.utils.formatUnits(data[data?.length-1] || 0, "ether")))
    }
  }, [data]);

  return {
    info,
    data,
    isSuccess,
    refetch
  };
};

export default useGetUserInfo;
