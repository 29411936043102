import api from "../index";

  const getAllUsers = () => {
    return api.get('/user/all')
  };


  const banUser = (id) => {
    return api.patch(`/admin/user/${id}/ban`)
  };


  const unbanUser = (id) => {
    return api.patch(`/admin/user/${id}/unban`)
  };

  const updateUserStatusApi = (id) => {
    return api.patch(`/admin/user/${id}/status`)
  };
  const updateUserInvestmentStatus = (id,data) => {
    return api.patch(`/admin/user/investment/status/${id}`,data)
  };
  
export { getAllUsers,banUser,unbanUser,updateUserInvestmentStatus,updateUserStatusApi };
