import { useEffect,useState } from "react";
import Classes from "./deductUserAmount.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ethers } from "ethers";
import { createStake } from "../../api/services/stake";
import useStake from "../../contract/hooks/stake/useDeductStake";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import SocketContext from "../../context/socket";
import { toast } from "react-toastify";
import { useContext } from "react";
import useGetUserInfo from "../../contract/hooks/stake/useGetUserInfo";
const DeductUserAmount = () => {
  const [data,setData] = useState({
    amount:"",
    address:""
  })
  const [loaderMessage, setLoaderMessage] = useState("");
  const { address } = useAccount();
  const user = useSelector((state) => state.auth?.user);
  const socket = useContext(SocketContext);
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, "Amount must be at least 1")
      .max(50000,"Amount must be less than or equal to 50000")
      .required("Amount is required"),
    address: Yup.string()
      .matches(/^0x[a-fA-F0-9]{40}$/, "Invalid wallet address")
      .required("Address is required"),
  });
  const handleSubmit = async (values) => {
    if(values.amount>info){
      toast.error("User don't have enought amount to deduct");
      return
    }
    setLoaderMessage("Approve the transaction from metamask");
    console.log(values);
    stake({
      args: [ethers.utils.parseEther(`${values.amount}`),values.address],
      from: address,
    });
    setData(values)
  };
  const formik = useFormik({
    initialValues: {
      amount: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
 
  const { values, handleChange } = formik;
  const {info,refetch} = useGetUserInfo(values.address)
  const { stake, stakeSentTx } = useStake(setLoaderMessage);
   

  useEffect(() => {
    const addStake = async () => {
      try {
        const res = await createStake({
          amount: Number(data.amount),
          txHash: stakeSentTx?.hash,
          type: "stack",
          userAddress:data.address
        });
      } catch (error) {
        setLoaderMessage(null);
        console.log(error,'error')
      }
    };
    if (stakeSentTx?.hash) {
      setLoaderMessage("Transaction is in progress please wait");
      addStake();
    }
  }, [stakeSentTx?.hash]);
  useEffect(() => {
    if (socket && user?._id) {
      const handleStake = () => {
        setLoaderMessage(null);
        refetch()
        toast.success("User amount deducted successfully");
        formik.resetForm();
      };
      socket.emit("join", `${user?._id}`);
      socket?.on("Stake", handleStake);

      return () => {
        socket?.off("Stake", handleStake);
        socket.emit("leave", `${user?._id}`);
      };
    }
  }, [socket]);
  return (
    <div className={Classes.userDeduction}>
      <div className={Classes.content}>
        <div className="d-flex align-items-center gap-2 mb-4 ps-3">
          {/* <img src={Leaf} alt="icon" /> */}
          <p className={Classes.title}>User Investment Deduction</p>
        </div>
        <div className={`${Classes.card} mx-3`}>
          <form onSubmit={formik.handleSubmit}>
            <h2 className={Classes.cardtitle}>Deduct User Investment</h2>
            <div className="my-4">
              <div className={Classes.label}>Amount</div>
              <input
                type="number"
                name="amount"
                className="baseInput d-block"
                placeholder="Enter the amount"
                autoComplete="off"
                onChange={handleChange}
                value={values.amount}
              />
              {formik.errors.amount && (
                <div className={Classes.textDanger}>{formik.errors.amount}</div>
              )}
            </div>

            <div>
              <div className={Classes.label}>User Address</div>
              <input
                type="text"
                name="address"
                className="baseInput d-block "
                placeholder="Enter the amount"
                onChange={handleChange}
                autoComplete="off"
                value={values.address}
              />
              {formik.errors.address && (
                <div className={Classes.textDanger}>{formik.errors.address}</div>
              )}
            </div>

            <div className="mt-4">
              <button
                className="btn"
                type="submit"
                disabled={!values.amount || !values.address||loaderMessage}
              >
                {loaderMessage?loaderMessage:"Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeductUserAmount;
